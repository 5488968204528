import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';


const imgUrl = require.context('../assets/images', true);

const paletaLink = [
    {
        nombre: 'benjaminmoore',
        link: 'https://www.benjaminmoore.com/en-us/color-overview/color-palettes',
        images: 'bjm-1.jpg',
    },
    {
        nombre: 'behr',
        link: 'https://www.behr.com/consumer/colors/paint/explore',
        images: 'behr-1.jpg',
    },
    {
        nombre: 'sherwin-williams',
        link: 'https://images.sherwin-williams.com/content_images/sw-pdf-sherwin-williams-colorc.pdf',
        images: 'SW-1.jpg',
    },
]



function PaletaColorContent(props) {
    return (
        <Container className='py-5'>
            <Row>
                {
                    paletaLink.map((item, index) => {
                        return (
                            <Col key={index} lg={4} sm={12}>
                                <a href={item.link} target={'_blank'}>
                                    <img src={imgUrl(`./directorios/${item.images}`)} alt={item.nombre} />
                                </a>
                            </Col>
                        )
                    })
                }
            </Row>
        </Container>
    );
}

export default PaletaColorContent;