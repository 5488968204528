import { useContext } from "react";
import { GlobalContext } from "../../components/data/GlobalContext";

import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";


const CallToActionThree = () => {
    const dbData = useContext(GlobalContext);
    return (
        <section className="call-to-action">
            <Container>
                <Row className="align-items-center">
                    <Col lg={9} md={8}>
                        <div className="call-to-action__content">
                            <div className="call-to-action__icon">
                                <i className="azino-icon-address"></i>
                            </div>
                            {
                                dbData.dbPrincipal.miles !== ' ' ?
                                    <h3 className="text-capitalize">

                                        we cover {dbData.dbPrincipal.miles} miles around {dbData.dbPrincipal.location[0].address}
                                    </h3>
                                    :
                                    <h3 className="text-capitalize">
                                        we cover the entire state of {dbData.dbPrincipal.location[1].address}
                                    </h3>
                            }
                        </div>
                    </Col>
                    <Col
                        lg={3}
                        md={3}
                        className="d-flex justify-content-start justify-content-md-center justify-content-xl-end justify-content-lg-end"
                    >
                        <Link to='/contact' className="thm-btn text-capitalize ">
                            Free estimate
                        </Link>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default CallToActionThree;
