import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { AiOutlineClose } from 'react-icons/ai';
import RedesContent from './RedesContent';


export default class ModalServices extends Component {
    render() {
        let modalStyle = {
            display: 'block',
            background: 'rgba(0,0,0,0.8)',
        }
        return (
            <div className='modal show fade' style={modalStyle}>
                <div className='modal-dialog modal-xl'>
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <h3 className='modal-title text-capitalize' style={{fontWeight:'bold'}}>{this.props.title}</h3>
                            <button type='button' className="close thm-btn" onClick={this.props.hide} data-bs-dismiss="modal" aria-label="Close">
                                <AiOutlineClose />
                            </button>
                        </div>
                        <div className='modal-body'>
                            <div className='pt-3'>{this.props.description}</div>
                        </div>
                        <div className="modal-footer flex justify-content-between footer-bottom">
                            <RedesContent />
                            <Link to='/contact' className='thm-btn text-capitalize'>
                                free estimate
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}