import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import NavsLink from "./NavsLink"
import { GlobalContext } from "../data/GlobalContext";


const SticyHeader = ({ extraClassName }) => {

    const dbData = useContext(GlobalContext);

    const [sticky, setSticky] = useState(false);
    const handleScroll = () => {
        if (window.scrollY > 130) {
            setSticky(true);
        } else if (window.scrollY < 130) {
            setSticky(false);
        }
    };
    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [sticky]);

    return (
        <div
            className={`stricky-header stricked-menu ${extraClassName} ${sticky === true ? "stricky-fixed" : " "
                }`}
        >
            <div className="container">
                <div className="logo-wh-box">
                    <Link to="/">
                        <img src={`${dbData.dbPrincipal.logo}`} width="200" alt=" logo" />
                    </Link>
                </div>
                <div className="sticky-header__content">
                    <NavsLink />
                </div>
            </div>
        </div>
    )

}

export default SticyHeader;