
const dbMenu = {
    dbMenu: [
        {
            name: `Home`,
            link: `/`,
        },
        {
            name: `About`,
            link: `/about`,
        },
        {
            name: `Services`,
            link: `/services`,
        },
        {
            name: `Gallery`,
            link: `/gallery`,
        },
        {
            name: `Contact`,
            link: `/contact`,
        },
    ],
}

export default dbMenu;