
import { BiCabinet } from 'react-icons/bi';


const IconTitle = ({ tagLine }) => {
    return (
        <div className="block-title">
            <p>
                <BiCabinet fontSize='50' color="#00345e" />
                <span className="ms-3 text-capitalize">{tagLine}</span>
            </p>
        </div>
    );
};

export default IconTitle;