import React, { useEffect, useState, useContext } from "react";
// //conexion con el context global para obtener los datos de la base de datos
// import axios from 'axios';

import { GlobalContext } from '../components/data/GlobalContext';

import { Helmet } from "react-helmet";
import Header from "./header/Header";
import SticyHeader from "./header/StickyHeader";
import HeaderTwo from "./header/HeaderTwo";
import Footer from "./footer/Footer";

//estilo
import "bootstrap/dist/css/bootstrap.min.css";
import "../assets/css/animate.min.css";
import "../assets/css/azino-icons.css";
import "../assets/css/fontawesome-all.min.css";
import "../assets/css/main.css";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/autoplay';
import 'swiper/css/effect-fade';
import '../assets/css/stylePersonal.css'



const Layout = ({ pageTitle, children }) => {

    const dbData = useContext(GlobalContext);
    const [hasMounted, setHasMounted] = useState(false);

    const handleRadius = () => {
        const dynamicRadius = document.querySelectorAll(".dynamic-radius");
        dynamicRadius.forEach(function (btn) {
            let btnHeight = btn.offsetHeight;
            btn.style.borderBottomLeftRadius = btnHeight / 2 + "px";
            btn.style.borderTopLeftRadius = btnHeight / 2 + "px";
        });
    };

    return (
        <GlobalContext.Provider value={dbData}>
            {/* <Header /> */}
            <HeaderTwo />
            <SticyHeader />
            <Helmet>
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <meta
                    name={dbData.dbSlogan[0].slogan}
                    content={dbData.dbHome[0].text}
                />
                <title>{pageTitle + " - " + dbData.dbPrincipal.name}</title>
                <link
                    href="https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Libre+Baskerville:ital,wght@0,400;0,700;1,400&display=swap"
                    rel="stylesheet"
                />
                <link rel="icon" type="image/png" sizes="32x32" href={`${dbData.dbPrincipal.favicon}`} />
            </Helmet>
            <div className="page-wrapper " id="wrapper">
                {children}
            </div>
            <Footer />
        </GlobalContext.Provider>
    );
}

export default Layout;