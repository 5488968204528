import { useContext } from 'react';
import { Container } from 'react-bootstrap';
import { GlobalContext } from '../../components/data/GlobalContext';


const imgUrl = require.context('../../assets/images', true);


function RedesContent(props) {
    const dbData = useContext(GlobalContext);
    return (
        <Container>
            <div className='contentRedes'>
                {
                    dbData.dbSocialMedia.directorios && dbData.dbSocialMedia.directorios[0].name !== ' ' ?
                        dbData.dbSocialMedia.directorios.map((items, index) => {
                            return (
                                <div key={index}>
                                    <a href={items.url} target='_blank'>
                                        <img src={imgUrl(`./directorios/${items.image}`)} alt={items.name} />
                                    </a>
                                </div>
                            )
                        }) : null
                }
            </div>

            <div className='contentRedes'>
                {
                    dbData.dbSocialMedia.redes && dbData.dbSocialMedia.redes[0].name !== ' ' ?
                        dbData.dbSocialMedia.redes.map((items, index) => {
                            return (
                                <div key={index}>
                                    <a href={items.url} target='_blank'>
                                        <img src={imgUrl(`./redes/${items.image}`)} alt={items.name} />
                                    </a>
                                </div>
                            )
                        }) : null
                }

            </div>
        </Container>
    );
}

export default RedesContent;