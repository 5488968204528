import { useContext } from "react";
import { GlobalContext } from "../data/GlobalContext";
import { Col, Container, Row } from "react-bootstrap";
import IconTitle from "../IconTitle";
import * as FaIcons from 'react-icons/fa';

// import Forms from "./Forms";
import FormContent from "./FormContent";

const ContactForm = () => {
    const dbData = useContext(GlobalContext);
    return (
        <section className="contact-page pt-120 pb-80">
            <Container>
                <Row>
                    <Col lg={5} className="d-flex align-items-center">
                        <div className="contact-page__content mb-40">
                            <IconTitle
                                tagLine={dbData.dbSlogan[1].slogan}
                            />
                            <h3 className="text-capitalize fw-bold">contact info</h3>
                            <p className="block-text mb-30 pr-10">
                                {dbData.dbAbout[1].text}
                            </p>
                            <div className="footer-social black-hover">
                                {
                                    dbData.dbSocialMedia.redes.map((item, index) => {
                                        const Social =
                                            FaIcons[
                                            item.icon
                                            ];
                                        return (
                                            <>
                                                {
                                                    item.name !== ' ' ?
                                                        <a
                                                            key={index}
                                                            href={item.url}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >
                                                            <Social />
                                                        </a>
                                                        : null
                                                }
                                            </>
                                        );
                                    })
                                }
                            </div>
                        </div>
                    </Col>
                    <Col lg={7}>
                        <FormContent />
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default ContactForm;
