import classes from './contadorvisita.module.css';

function ContadorVisita(props) {
    return (
        <div className={classes.visor_counter}>
            <div className="elfsight-app-1bd19309-9ef2-48c7-b23e-5d3ac8aa6b9e"></div>
        </div>
    );
}

export default ContadorVisita;